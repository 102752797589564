var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "card card-custom"
  }, [_c('div', {
    staticClass: "card-header border-0 pt-6 pb-0"
  }, [_c('div', {
    staticClass: "card-title"
  }, [_c('h3', {
    staticClass: "card-label font-weight-bolder mb-0"
  }, [_vm._v(_vm._s(_vm.$t("Declarations of Detachment")))])]), _c('div', {
    staticClass: "card-toolbar"
  }, [_c('button', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.create-declaration-detachment-modal",
      modifiers: {
        "create-declaration-detachment-modal": true
      }
    }],
    staticClass: "btn btn-light-primary font-weight-bolder"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('span', {
    staticClass: "svg-icon svg-icon-md"
  }, [_c('inline-svg', {
    attrs: {
      "src": "/media/svg/icons/Files/File.svg"
    }
  })], 1), _vm._v(" " + _vm._s(_vm.$t("Add new")) + " ")])])])]), _c('div', {
    staticClass: "card-body detail"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm._l(_vm.items, function (dd, i) {
    return _c('div', {
      key: i,
      staticClass: "d-flex flex-wrap border-bottom align-items-center p-4"
    }, [_c('div', {
      staticClass: "d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2"
    }, [_c('span', {
      staticClass: "text-dark font-weight-bold mb-1 font-size-lg"
    }, [_vm._v(" " + _vm._s(_vm.DateService.format(dd.declaration_date, _vm.dateFormat)))])]), _c('a', {
      staticClass: "btn btn-icon btn-light mr-4",
      attrs: {
        "id": "download-declaration".concat(dd.id),
        "href": dd.attachment,
        "target": "_blank"
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary"
    }, [_c('inline-svg', {
      attrs: {
        "title": _vm.$t('Download attachment'),
        "src": "/media/svg/icons/General/Clip.svg"
      }
    })], 1), _c('b-tooltip', {
      attrs: {
        "target": "download-declaration".concat(dd.id),
        "triggers": "hover"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Download attachment")) + " ")])], 1), _c('button', {
      staticClass: "btn btn-icon btn-light mr-4",
      on: {
        "click": function click($event) {
          return _vm.onDeleteBtnClick(dd.id);
        }
      }
    }, [_c('span', {
      staticClass: "svg-icon svg-icon-md svg-icon-primary",
      attrs: {
        "id": "delete-declaration".concat(dd.id),
        "title": _vm.$t('Remove')
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": "/media/svg/icons/General/Trash.svg"
      }
    }), _c('b-tooltip', {
      attrs: {
        "target": "delete-declaration".concat(dd.id),
        "triggers": "hover"
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("Delete attachment")) + " ")])], 1)])]);
  }), _vm.items.length == 0 ? _c('p', [_vm._v(" " + _vm._s(_vm.$t("No attachment have been added to this assignement")) + " ")]) : _vm._e()], 2), _c('SecondmentCreateDetachmentModal', {
    attrs: {
      "secondment": _vm.secondment.id
    },
    on: {
      "refresh": _vm.loadDocuments
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }