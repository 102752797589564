<template>
  <div>
    <div class="card card-custom">
      <div class="card-header border-0 pt-6 pb-0">
        <div class="card-title">
          <h3 class="card-label font-weight-bolder mb-0">{{ $t("Declarations of Detachment") }}</h3>
        </div>
        <div class="card-toolbar">
          <button v-b-modal.create-declaration-detachment-modal class="btn btn-light-primary font-weight-bolder">
            <div class="d-flex">
              <span class="svg-icon svg-icon-md">
                <inline-svg src="/media/svg/icons/Files/File.svg" />
              </span>
              {{ $t("Add new") }}
            </div>
          </button>
        </div>
      </div>
      <div class="card-body detail">
        <div class="row">
          <div class="col-12">
            <div v-for="(dd, i) in items" :key="i" class="d-flex flex-wrap border-bottom align-items-center p-4">
              <div class="d-flex flex-column flex-grow-1 my-lg-0 my-2 mr-2">
                <span class="text-dark font-weight-bold mb-1 font-size-lg"> {{ DateService.format(dd.declaration_date,
                  dateFormat) }}</span>
              </div>
              <a :id="`download-declaration${dd.id}`" :href="dd.attachment" target="_blank"
                class="btn btn-icon btn-light mr-4">
                <span class="svg-icon svg-icon-md svg-icon-primary">
                  <inline-svg :title="$t('Download attachment')" src="/media/svg/icons/General/Clip.svg" />
                </span>
                <b-tooltip :target="`download-declaration${dd.id}`" triggers="hover">
                  {{ $t("Download attachment") }}
                </b-tooltip>
              </a>
              <button class="btn btn-icon btn-light mr-4" @click="onDeleteBtnClick(dd.id)">
                <span :id="`delete-declaration${dd.id}`" class="svg-icon svg-icon-md svg-icon-primary"
                  :title="$t('Remove')">
                  <inline-svg src="/media/svg/icons/General/Trash.svg" />
                  <b-tooltip :target="`delete-declaration${dd.id}`" triggers="hover">
                    {{ $t("Delete attachment") }}
                  </b-tooltip>
                </span>
              </button>
            </div>
            <p v-if="items.length == 0">
              {{ $t("No attachment have been added to this assignement") }}
            </p>
          </div>
          <SecondmentCreateDetachmentModal :secondment="secondment.id" @refresh="loadDocuments">
          </SecondmentCreateDetachmentModal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SecondmentCreateDetachmentModal from '@/view/components/modals/secondment/SecondmentCreateDetachmentModal.vue';
import SecondmentDeclarationService from "@/core/services/secondment/secondment-declaration.service.js";
import DateService from "@/core/services/date.service";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { backendErrorSwal } from "@/core/helpers/swal";
import { perPageOptions, tableOptions } from "@/core/config/datatable.config.js"

export default {
  components: {
    SecondmentCreateDetachmentModal,
  },
  props: {
    secondment: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      DateService,
      loading: false,
      fields: [
        { label: 'Uploaded at', key: "date", sortable: true, class: "col-dates" },
        { label: 'Attachments', key: "attachment" },
        { label: "Actions", key: "actions", class: "align-end col-actions" },
      ],
      items: [],
      perPageOptions: perPageOptions,
      tableOptions: tableOptions,

    };
  },

  computed: {
    ...mapGetters("user", ["dateFormat"]),
    tableProps() {
      return {
        busy: this.loading,
        fields: this.fields,
      };
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("Assignments")},
      { title: this.$t("Declarations of Detachment") },
    ]);
    await this.loadDocuments()
  },

  methods: {
    async loadDocuments() {
      this.loading = true

      const declarations = await SecondmentDeclarationService.getAll(this.secondment.id);
      this.items = declarations.results

      this.loading = false
    },

    async onDeleteBtnClick(id) {
      await Swal.fire({
        title: this.$t("This document will be deleted, are you sure?"),
        icon: "warning",
        confirmButtonText: this.$t("Yes delete"),
        showCancelButton: true,
        cancelButtonText: this.$t("No, do NOT delete"),
      }).then(async result => {
        if (result.isConfirmed) {
          try {
            await SecondmentDeclarationService.delete(id);
            Swal.fire(this.$t("Success"), this.$t("Document deleted"), "success");
            this.loadDocuments();
          } catch (err) {
            let message = ''
            if (err?.response?.status === 400) {
              message = err.response.data;
            } else {
              message = err?.response?.data?.detail ?? this.$t("Unable to delete the document")
            }
            backendErrorSwal(err, message);
          }
        }
      });
    },
  }
};
</script>

<style>
.col-dates {
  max-width: 120px;
}
</style>
